@font-face {
  font-family: "kurdshop";
  src: url("./font/kurdshop.eot?88332289");
  src: url("./font/kurdshop.eot?88332289#iefix") format("embedded-opentype"),
    url("./font/kurdshop.woff2?88332289") format("woff2"),
    url("./font/kurdshop.woff?88332289") format("woff"),
    url("./font/kurdshop.ttf?88332289") format("truetype"),
    url("./font/kurdshop.svg?88332289#kurdshop") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'kurdshop';
      src: url('../font/kurdshop.svg?88332289#kurdshop') format('svg');
    }
  }
  */

[class^="icon-"].sm,
[class*=" icon-"].sm {
  font-size: 0.8rem !important;
  width: 0.8rem;
  height: 0.8rem;
  line-height: 0.8rem;
}
[class^="icon-"].xl,
[class*=" icon-"].xl {
  font-size: 1.5rem !important;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
[class^="icon-"].xxl,
[class*=" icon-"].xxl {
  font-size: 1.8rem !important;
  width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;
}
[class^="icon-"].lg,
[class*=" icon-"].lg {
  font-size: 1.3rem !important;
  width: 1.3rem;
  height: 1.3rem;
  line-height: 1.3rem;
}
[class^="icon-"],
[class*=" icon-"] {
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "kurdshop";
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-message:before { content: '\e800'; } /* '' */
.icon-play-btn:before { content: '\e801'; } /* '' */
.icon-monitor:before { content: '\e802'; } /* '' */
.icon-radio:before { content: '\e803'; } /* '' */
.icon-search-normal:before { content: '\e804'; } /* '' */
.icon-slack:before { content: '\e805'; } /* '' */
.icon-telegram:before { content: '\e806'; } /* '' */
.icon-twitter:before { content: '\e807'; } /* '' */
.icon-whatsapp:before { content: '\e808'; } /* '' */
.icon-youtube:before { content: '\e809'; } /* '' */
.icon-arrow-down:before { content: '\e80a'; } /* '' */
.icon-arrow-left:before { content: '\e80b'; } /* '' */
.icon-arrow-right:before { content: '\e80c'; } /* '' */
.icon-arrow-up:before { content: '\e80d'; } /* '' */
.icon-clock:before { content: '\e80e'; } /* '' */
.icon-edit-2:before { content: '\e80f'; } /* '' */
.icon-eye:before { content: '\e810'; } /* '' */
.icon-facebook:before { content: '\e811'; } /* '' */
.icon-instagram:before { content: '\e812'; } /* '' */
.icon-linked-in:before { content: '\e813'; } /* '' */
.icon-menu:before { content: '\e814'; } /* '' */
.icon-message-tick:before { content: '\e815'; } /* '' */
.icon-user:before { content: '\e816'; } /* '' */
.icon-messages-2:before { content: '\e817'; } /* '' */
.icon-more:before { content: '\e818'; } /* '' */
.icon-save:before { content: '\e819'; } /* '' */
.icon-brand-tiktok:before { content: '\e81a'; } /* '' */
